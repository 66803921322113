import { apiClient } from '@api/client';
import { nonNull } from '@helpers/non-null';
import { ErrorPage404, ErrorPage500 } from '@pages/ErrorPage';
import { useMutation } from '@tanstack/react-query';
import React, { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface MondayOAuthInstallProps {
  loader: ReactElement;
}

export default function MondayOAuthInstall({
  loader
}: MondayOAuthInstallProps) {
  const [searchParams] = useSearchParams();
  const mondayToken = searchParams.get('token');
  const { mutate, isError } = useMutation({
    mutationFn: async () => {
      const {
        data: { url }
      } = await apiClient.monday.mondayControllerInstall({
        token: nonNull(mondayToken)
      });
      window.location.href = url;
    }
  });
  useEffect(() => {
    mutate();
  }, [mutate]);
  useEffect(() => {
    if (mondayToken) mutate();
  }, [mondayToken, mutate]);
  if (!mondayToken) {
    return <ErrorPage404 />;
  }
  if (isError) {
    return <ErrorPage500 />;
  }
  return loader;
}
